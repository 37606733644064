import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Article Layout with CSS Grid",
  "date": "2018-09-5",
  "promo": "grids",
  "description": "How to easily build sharp blog post layouts with CSS Grid"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Yesterday I shipped my brand new `}<a parentName="p" {...{
        "href": "https://gedd.ski"
      }}>{`gedd.ski`}</a>{` site. This new version is built with my `}<em parentName="p">{`dream`}</em>{` stack and I'll write up a post on that soon (spoiler: `}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby`}</a>{` and `}<a parentName="p" {...{
        "href": "https://mdxjs.com/"
      }}>{`MDX`}</a>{` are game-changers).`}</p>
    <p>{`For the blog post page (the page you're looking at right now) I wanted a mobile-friendly layout where the text was centered and readable, where the images/code examples are wide. Similar to the layout on Medium.com (minus the desperate popups begging people to sign up & pay).`}</p>
    <p>{`I discovered a pretty exciting & simple way to accomplish this layout with CSS Grid. Check it out!`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/responsive-new-site.gif",
        "alt": "responsive new site layout with css grid"
      }}></img></p>
    <p>{`Ready to have your mind blown? Look how easy grid makes this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.post {
  display: grid;
  grid-template-columns:
    minmax(1.2rem, 1fr)
    minmax(auto, 57ch)
    minmax(1.2rem, 1fr);
}
`}</code></pre>
    <p>{`This code creates three flexible grid columns.`}</p>
    <p>{`The outer columns use `}<inlineCode parentName="p">{`minmax(1.2rem, 1fr)`}</inlineCode>{`. This makes these columns a `}<em parentName="p">{`minimum`}</em>{` width of `}<inlineCode parentName="p">{`1.2rem`}</inlineCode>{` and a `}<em parentName="p">{`maxiumum`}</em>{` width of `}<inlineCode parentName="p">{`1fr`}</inlineCode>{`.`}</p>
    <p>{`The inner column for text uses `}<inlineCode parentName="p">{`minmax(auto, 57ch)`}</inlineCode>{`. The minimum size of `}<inlineCode parentName="p">{`auto`}</inlineCode>{` makes the column size to the width of the text as it wraps — so it looks great on tiny screens. The maximum size of `}<inlineCode parentName="p">{`57ch`}</inlineCode>{` makes sure that column never gets bigger than 57 characters, keeping the text from getting too wide and unreadable on huge screens.`}</p>
    <p>{`Here's a live example you can play with!`}</p>
    <CodeExample live={true} showCSS={`
.post {
  display: grid;
  grid-template-columns: minmax(1.2rem, 1fr) minmax(auto, 57ch) minmax(1.2rem, 1fr);
}\n
/* position text paragraphs into the second (middle) column */
p {
  grid-column: 2;
}\n
/* position images to take up all three columns */
img {
  grid-column: 1 / 4;
  width: 100%;
  max-width: 100ch;
  justify-self: center;
}
`} fixedCSS={`
  img {
    max-width: 100%;
  }
  .post {
    height: 600px;
  }
  p {
    font-size: 1.4rem;
  }
`} html={`
  <div class="post">
    <p class="narrow">It's not a question of where he grips it! It's a simple question of weight ratios! A five ounce bird could not carry a one pound coconut.</p>
    <img class="wide" src="/img/five-ounce-bird.jpg"></img>
  </div>
`} mdxType="CodeExample" />
    <p>{`The only other thing to note is how we positioned the items into the grid using `}<inlineCode parentName="p">{`grid-column`}</inlineCode>{`.`}</p>
    <p>{`Play with the above code and see how it reacts if you change the size of the columns or the item positioning. Remember the grid tracks are invisible, but you can see 'em with your browser's `}<a parentName="p" {...{
        "href": "/post/invisible-grid/"
      }}>{`Grid Inspector`}</a>{`.`}</p>
    <h2>{`Suspiciously Easy`}</h2>
    <p><img parentName="p" {...{
        "src": "/img/shiny.jpg",
        "alt": null
      }}></img></p>
    <p>{`Sure, we could have built this layout using oldschool CSS: add in a couple of wrapper divs, don't forget padding and auto margin, set max-width, throw in a couple of media queries, and we're done.`}</p>
    <p>{`But Grid makes it `}<em parentName="p">{`easy`}</em>{`. And intuitive. And `}<strong parentName="p">{`maintainable`}</strong>{`. CSS has needed something like this for a loooong time.`}</p>
    <p>{`CSS Grid is the gift that keeps on giving. The building blocks it adds to the web are pretty simple. But we're only beginning to discover the awesome ways we can combine them to build awesome stuff!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      